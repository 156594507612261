import React, { useState, useEffect } from 'react';

import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'

const r = str => {
  const input = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const output = 'NOPQRSTUVWXYZABCDEFGHIJKLMnopqrstuvwxyzabcdefghijklm';
  const encoded = []
  for (let i = 0; i < str.length; i++) {
    const index = input.indexOf(str[i]);
    if (index >= 0) {
      encoded.push(output[index])
    }
    else {
      encoded.push(str[i])
    }
  }

  return encoded.join('');
}
const at = '@'

const isBrowser = () => typeof window !== "undefined"

const Obs = () => {
  const [e, setE] = useState('');
  isBrowser() && window.setTimeout(() => {
    const eparts = [
      r('pbagnpg-ubzrohlvatthvqr'), 'x', r('ubzrohlvatthvqr.pb.hx')
    ]
    setE(eparts[0] + at + eparts[2])
  }, 2125)

  if (e) return <code><a target="_blank" href={"mailto:" + e}>{e}</a></code>

  return <span><i>(loading... please wait 3 seconds)</i></span>
}

const PageContact = props => (
  <Layout {...props}>
    <Seo title='Contact' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Get in touch"
          subheader='How to get in touch' />
        <Divider />

        <p>Get in touch with me with article suggestions, updated links, sponsored content, questions or anything else.</p>
        <p>(Sponsored links will be clearly marked as sponsored and will not influence the articles on this site)</p>

        <p>Get in touch by emailing me at: {' '}
          <Obs /> </p>

      </Main>
      <Sidebar>
        <Commitment />
        <Divider />
        <ContactInfo />
      </Sidebar>
    </Stack>
  </Layout>
)

export default PageContact
